<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth/auth.store";
import PasswordInput from "~/components/Pages/Auth/steps/PasswordInput.vue";
import { useAuthValidation } from "~/composables/auth/useAuthValidation";
import type { IToken } from "~/utils/interfaces/auth-interfaces";
import { useAuthApi } from "~/composables/auth/useAuthApi";
import { useTokens } from "~/composables/auth/useTokens";
import { useAuth } from "~/composables/auth/useAuth";
import { useFeedback } from "~/composables/feedback/useFeedback";
import LoadButton from "~/components/Common/Buttons/LoadButton.vue";
import { useCreateCompaniesStore } from "~/store/companies/create-company.store";

const authStore = useAuthStore();
const { connexion } = storeToRefs(authStore);
const auth = useAuth();
const authApi = useAuthApi();
const authValidation = useAuthValidation();
const feedback = useFeedback();

const $e = await authValidation.stepLoginPassword();

const inputPassword = ref<{ inputPassword: HTMLInputElement } | null>(null);
const $error = ref<string | null>();
const loadReset = ref<boolean>(false);
const loadLogin = ref<boolean>(false);


onMounted(() => {
  if (inputPassword.value?.inputPassword) {
    inputPassword.value?.inputPassword.focus();
  }
});

watch($e, () => {
  $error.value = null;
  if ($e.value.$error) {
    $error.value = $e.value.$errors.find(Boolean)?.$message as string;
  }
});

async function clickLoginPassword() {
  loadLogin.value = true;

  await $e.value.$validate();

  if ($e.value.$error) {
    loadLogin.value = false;
    return;
  }

  if (connexion.value.login_email && connexion.value.login_password) {
    try {
      const hashedPassword = auth.hashPassword(connexion.value.login_password);
      const { data, error } = await authApi.login(
        connexion.value.login_email,
        hashedPassword
      );

      if (!data.value) {
        $error.value =
          "Ce mot de passe ne correspond pas à l’adresse email indiquée.";
        loadLogin.value = false;

        return;
      }

      await authStore.setTokens(data.value as IToken);
      await authStore.login();
      authStore.initAmplitude();
      return auth.handleExternalRedirect();
    } catch (error) {
      loadLogin.value = false;
    } finally {
      loadLogin.value = false;
    }
  }
}

async function resetPassword() {
  loadReset.value = true;
  try {
    if (connexion.value.login_email) {
      const { data } = await authApi.generateResetPassword(
        connexion.value.login_email
      );

      if (!data.value) {
        feedback.error(`Une erreur est survenue`, "small");
        loadReset.value = false;
        return;
      }

      connexion.value.show_reset_password_sent = true;
    }
  } catch (error) {
    loadReset.value = false;
  }
  finally {
    loadReset.value = false;
  }
}
</script>

<template>
  <form class="connexion__form">
    <div class="connexion__form_input_container">
      <label for="connexion_password" class="body_med text-secondary-color">Mot de passe *</label>
      <PasswordInput id="connexion_password" ref="inputPassword" v-model="connexion.login_password"
        :data-error="!!$error" placeholder="********"></PasswordInput>
      <p v-if="$error" class="text-primary-red-color body_med">
        {{ $error }}
      </p>
    </div>

    <div class="connexion__form_button_container">
      <LoadButton v-model="loadLogin" class="button__default w-full body_med" :text-confirm="`Se connecter`"
        @click="clickLoginPassword()">
      </LoadButton>
      <LoadButton :reverse="true" v-model="loadReset" class="button_border__default !border-none w-full body_med"
        :text-confirm="`Mot de passe oublié ?`" @click="resetPassword()">
      </LoadButton>
    </div>
  </form>
</template>

<style lang="scss" scoped></style>
